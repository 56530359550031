import React from 'react'

function CodeLink({ href }) {
  if (!href) return null

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      Code on GitHub →
    </a>
  )
}

export default CodeLink
