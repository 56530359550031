import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Details from '../../components/Details'
import SEO from '../../components/SEO'
import CodeLink from '../../components/CodeLink'
import DividerLine from '../../components/DividerLine'
import { rhythm } from '../../utils/typography'

function BlogPostTemplate({ data }) {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const tags = post.frontmatter.tags
  const date = post.frontmatter.date

  return (
    <Layout title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1>{post.frontmatter.title}</h1>
          <Details date={date} tags={tags} />
          {post.frontmatter.code && (
            <p>
              <CodeLink href={post.frontmatter.code} />
            </p>
          )}
        </header>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <footer
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: rhythm(2),
          }}
        >
          <Link to="/">← All posts</Link>
          <CodeLink href={post.frontmatter.code} />
        </footer>
      </article>
      <DividerLine />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY")
        tagline
        description
        code
        tags
      }
    }
  }
`
