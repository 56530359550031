import React from 'react'
import Design from '../Design'
import Header from '../Header'
import Bio from '../Bio'
import Footer from '../Footer'
import styles from './Layout.module.css'

function Layout({ title, children }) {
  return (
    <div className={styles.base}>
      <Design />
      <Header title={title} />
      <main>{children}</main>
      <Bio />
      <Footer />
    </div>
  )
}

export default Layout
