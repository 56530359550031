import Typography from "typography"
import sutroTheme from "typography-theme-sutro"
import * as colors from './colors'

sutroTheme.overrideThemeStyles = ({ rhythm }) => ({
  body: {
    color: colors.base,
  },
  'a': {
    color: colors.primary,
  },
  'h1, h2, h3, h4, h5, h6': {
    fontWeight: 300,
  },
  'h1': {
    fontSize: rhythm(1.125),
    marginTop: rhythm(1),
    marginBottom: rhythm(1.25),
  }
})

delete sutroTheme.googleFonts

const typography = new Typography(sutroTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
